<template>
  <Modal
    title="Booking Details"
    modal-classes="modal-xl modal-top"
    :disable-save="disableSave()"
    @save="save"
  >
    <div class="mb-4">
      <div class="form-group">
        <div class="alert alert-warning">
          Some items have missing Assembly Dates, either select a date below to
          fill all items with the same date or press close and enter them
          manually.
        </div>

        <label for="booking_date">Assembly Date</label>
        <FormDate v-model="data.assembly_date" name="assembly_date" />
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import Modal from "@/components/modal.vue";
import FormDate from "@/components/form/date.vue";

const emit = defineEmits(["save"]);

const props = defineProps({
  job: {
    type: Object,
    required: true,
  },
});

const data = ref({
  assembly_date: null,
});

function save() {
  emit("save", data.value);
}

function disableSave() {
  return data.value.assembly_date === null;
}
</script>

<style scoped></style>
