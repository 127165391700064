<template>
  <table class="table table-bordered table-striped table-vcenter">
    <thead>
      <tr>
        <slot name="headings"></slot>
      </tr>
    </thead>
    <tbody>
      <tr v-if="loading">
        <td colspan="99" class="text-center">Loading...</td>
      </tr>
      <tr v-else-if="tableContents.value.data.length == 0">
        <td colspan="99" class="text-center">Nothing to show</td>
      </tr>
      <slot v-else name="rows" :data="tableContents.value.data"></slot>
    </tbody>
  </table>
  <div
    v-if="
      !loading &&
      tableContents.value.meta &&
      tableContents.value.meta.links.length > 3
    "
    class="mt-2"
  >
    <ul class="pagination pagination-sm justify-content-end">
      <template
        v-for="(link, index) in tableContents.value.meta.links"
        :key="index"
      >
        <li
          v-if="link.url !== null || (index !== lastLink && index !== 0)"
          class="page-item"
          :class="{ active: link.active }"
        >
          <span
            v-if="link.url === null"
            class="page-link"
            v-html="link.label"
          ></span>
          <button
            v-else
            class="page-link"
            @click.prevent="getData(link.url)"
            v-html="link.label"
          ></button>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue";
import debounce from "lodash/debounce";
import { Eventbus } from "@/components/eventbus.js";
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
  limit: Number,
  orderBy: String,
  orderAsc: Boolean,
  refreshOn: String,
  search: String,
  url: String,
  filters: Object,
});

const tableContents = reactive({});

// this only works if tableContents is reactive
const lastLink = computed(() => tableContents.value.meta?.links.length - 1);

Eventbus.on(props.refreshOn, () => getData());

watch(
  () => props.search,
  () => getData(props.url) // reset url on search term change
);
watch(
  () => props.limit,
  () => getData()
);
watch(
  () => props.orderAsc,
  () => getData()
);
watch(
  () => props.orderBy,
  () => getData()
);
if (props.filters) {
  watch(
    props.filters,
    () => {
      loading.value = true;
      getData();
    },
    { deep: true }
  );
}

let currentURL = null;

const loading = ref(true);

const getData = debounce(async function (url) {
  url = url || currentURL || props.url;
  if (currentURL !== url) {
    currentURL = url;
  }

  const urlToFetch = new URL(url);

  if (props.search) {
    urlToFetch.searchParams.set("search", props.search);
  }

  if (props.limit) {
    urlToFetch.searchParams.set("limit", props.limit);
  }

  if (props.orderBy) {
    let orderBy = props.orderBy;
    if (props.orderAsc === false) {
      orderBy = `-${orderBy}`;
    }
    urlToFetch.searchParams.set("sort", orderBy);
  }

  if (props.filters) {
    for (const [key, value] of Object.entries(props.filters)) {
      if (value) {
        urlToFetch.searchParams.set(key, value);
      }
    }
  }

  loading.value = true;
  const res = await useFetch(urlToFetch);
  tableContents.value = await res.json();

  // if you have deleted the last element on the current page, reset to first page
  if (
    tableContents.value.data.length == 0 &&
    url.indexOf("page=") >= 0 &&
    url.indexOf("page=1") < 0
  ) {
    getData(props.url);
  }

  loading.value = false;
}, 500);

getData(props.url);

defineExpose({
  refresh: getData,
});
</script>
