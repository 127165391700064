<template>
  <VueDatePicker
    v-bind="{
      disabled,
      name,
      ...datePickerProps,
    }"
    v-model="model"
    :uid="name"
    :format="timePicker ? 'Pp' : 'P'"
    locale="en-GB"
    :position
    model-type="yyyy-MM-dd'T'HH:mm:ssxxxxx"
    :teleport
    :enable-time-picker="timePicker"
    :format-locale="enGB"
    auto-apply
    :placeholder
    :aria-describedby="`${name}-error`"
    :min-date="minDate"
    time-picker-inline
    minutes-increment="15"
    minutes-grid-increment="15"
    :start-time="{
      minutes: 0,
    }"
    :close-on-scroll="false"
    six-weeks="fair"
    @cleared="emit('cleared')"
    @update-month-year="emit('update-month-year', $event)"
  >
    <slot v-for="(_, slotName) in $slots" :name="slotName" />
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
  </VueDatePicker>
</template>

<script setup>
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const emit = defineEmits(["cleared", "update-month-year"]);

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    required: false,
  },
  error: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  timePicker: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: "left",
  },
  /** @type {import('vue').PropType<import('@vuepic/vue-datepicker').VueDatePickerProps>>} */
  datePickerProps: {
    type: Object,
    default: () => null,
  },
  teleport: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "Select date",
  },
  minDate: {
    type: [String, Date],
    default: null,
  },
});

const model = defineModel({
  type: [String, Date],
  default: null,
  get: (value) => {
    return value ? format(value, "yyyy-MM-dd'T'HH:mm:ssxxxxx") : null;
  },
});
</script>

<style></style>
