<template>
  <div>
    <label
      v-if="label"
      :for="name"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <select
      :id="name"
      v-model="internalValue"
      :name="name"
      :disabled="disabled"
      class="form-select"
    >
      <option value="" disabled selected>Select a rate</option>
      <option v-for="value in values" :key="value.id" :value="value">
        {{ value[fieldName] }}
      </option>
    </select>
    <p v-if="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const emit = defineEmits(["updated"]);

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    required: false,
  },
  error: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  values: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fieldName: {
    type: String,
    required: true,
  },
});

const internalValue = ref(props.modelValue);
watch(internalValue, (newValue) => {
  emit("updated", newValue);
});
</script>

<style></style>
