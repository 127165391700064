<template>
  <!-- fixed size required unfortunately -->
  <canvas
    ref="quotes"
    :width="width"
    :height="height"
    style="display: block; box-sizing: border-box"
  ></canvas>
</template>

<script setup>
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { ref, onMounted } from "vue";

const props = defineProps({
  ajaxUrl: String,
  height: String,
  width: String,
  graphData: Object,
});

Chart.register([
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Filler,
  Legend,
]);

const quotes = ref();

const config = {
  type: "bar",
  data: {
    labels: props.graphData.labels,
    datasets: [
      {
        label: "All quotes",
        fill: true,
        backgroundColor: "rgba(73, 186, 232, .7)",
        borderColor: "transparent",
        pointBackgroundColor: "rgba(73, 186, 232, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(73, 186, 232, 1)",
        data: props.graphData.all_totals,
      },
      {
        label: "Lost quotes",
        fill: true,
        backgroundColor: "rgba(255, 99, 132, .7)",
        borderColor: "transparent",
        pointBackgroundColor: "rgba(100, 116, 139, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(100, 116, 139, 1)",
        data: props.graphData.rejected_totals,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          drawBorder: false,
        },
      },
      y: {
        display: true,
        grid: {
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return "£" + value.toLocaleString();
          },
        },
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        labels: {
          boxHeight: 10,
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              context.dataset.label + ": £" + context.parsed.y.toLocaleString()
            );
          },
        },
      },
    },
  },
};

onMounted(() => {
  new Chart(quotes.value.getContext("2d"), config);
});
</script>
